.skills {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.skill-category {
  margin-bottom: 2rem;
}

.skill-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.skill-card span {
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
}

.skill-icon {
  transition: color 0.3s, transform 0.3s;
}

.skill-card:hover .skill-icon {
  transform: scale(1.2);
}

/* Specific colors for each icon */
.skill-icon.react {
  color: #61dafb;
}

.skill-icon.nodejs {
  color: #339933;
}

.skill-icon.js {
  color: #f7df1e;
}

.skill-icon.python {
  color: #3776ab;
}

.skill-icon.angular {
  color: #c3002f;
}

.skill-icon.java {
  color: #007396;
}

.skill-icon.aws {
  color: #ff9900;
}

.skill-icon.git {
  color: #f05032;
}

.skill-icon.docker {
  color: #2496ed;
}

.skill-icon.javascript {
  color: #f7df1e;
}

.skill-icon.flutter {
  color: #02569b;
}

.skill-icon.server {
  color: #3e3e3e;
}

.skill-icon.code {
  color: #5a5a5a;
}

.skill-icon.cloud {
  color: #f0f8ff;
}

.skill-icon.database {
  color: #4db33d;
}

/* Portfolio.css */

.item-wrap {
  position: relative;
  overflow: hidden;
}

.item-wrap img {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.item-wrap img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.item-wrap img.expanded {
  transform: scale(1.5);
  z-index: 10;
}

#portfolio-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.portfolio-item {
  margin: 10px;
  flex: 1 0 calc(25% - 20px); /* Adjust width as needed */
}
